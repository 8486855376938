import React, { SVGProps } from 'react'

const FlagSpain = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={16}
    viewBox="0 0 20 15"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M20 12.222a2.222 2.222 0 01-2.222 2.222H2.222A2.222 2.222 0 010 12.222v-10A2.222 2.222 0 012.222 0h15.556A2.222 2.222 0 0120 2.222v10z"
      fill="#C60A1D"
    />
    <path d="M0 3.889h20v6.667H0V3.889z" fill="#FFC400" />
    <path d="M5 6.667v1.666a1.667 1.667 0 103.333 0V6.667H5z" fill="#EA596E" />
    <path d="M6.667 6.111h1.666v1.667H6.667V6.11z" fill="#F4A2B2" />
    <path d="M5 6.111h1.667v1.667H5V6.11z" fill="#DD2E44" />
    <path
      d="M6.667 6.111c.92 0 1.666-.373 1.666-.833 0-.46-.746-.834-1.666-.834-.92 0-1.667.373-1.667.834 0 .46.746.833 1.667.833z"
      fill="#EA596E"
    />
    <path
      d="M6.667 5.278c.92 0 1.666-.187 1.666-.417 0-.23-.746-.417-1.666-.417-.92 0-1.667.187-1.667.417 0 .23.746.417 1.667.417z"
      fill="#FFAC33"
    />
    <path
      d="M3.889 6.111h.555V10H3.89V6.11zm5 0h.555V10H8.89V6.11z"
      fill="#99AAB5"
    />
    <path
      d="M3.333 9.444H5V10H3.333v-.556zm5 0H10V10H8.333v-.556zM3.89 5.555h.555v.556H3.89v-.556zm5 0h.555v.556H8.89v-.556z"
      fill="#66757F"
    />
  </svg>
)

export default FlagSpain
