import React, { SVGProps } from 'react'

const FlagItaly = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={16}
    viewBox="0 0 20 15"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M20 12.222a2.223 2.223 0 01-2.222 2.223h-4.445V0h4.445A2.222 2.222 0 0120 2.222v10z"
      fill="#CE2B37"
    />
    <path
      d="M2.222 0A2.222 2.222 0 000 2.222v10a2.222 2.222 0 002.222 2.223h4.445V0H2.222z"
      fill="#009246"
    />
    <path d="M6.667 0h6.666v14.444H6.667V0z" fill="#EEE" />
  </svg>
)

export default FlagItaly
