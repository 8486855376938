import React, { SVGProps } from 'react'

const FlagSwitzerland = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={16}
    viewBox="0 0 20 16"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <rect width={20} height={15.07} rx={2} fill="#D32D27" />
    <path
      d="M14 6.387h-2.857v-2.87H8.857v2.87H6v2.26h2.857v2.907h2.286V8.647H14v-2.26z"
      fill="#fff"
    />
  </svg>
)

export default FlagSwitzerland
